<ng-container *transloco="let t; prefix: 'PLATFORM-SELECTOR'">
  <div class="container vh-100 d-flex align-items-center">
    <div class="w-100" *ngIf="platforms">
      <h3 class="my-3">{{ t('title') }}</h3>

      <div class="my-5">
        <label class="form-label ps-2">
          {{ t('texts.selectorLabel') }}
        </label>
        <div>
          <p-dropdown
            [options]="platforms"
            [(ngModel)]="selectedPlatform"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{t('texts.selectorPlaceholder')}}">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="flex align-items-center gap-2">
                <div>{{ selectedOption.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <button class="btn btn-primary mt-4" (click)="switchPlatform()">
          {{ t('texts.selectPlatform') }}
        </button>
      </div>
    </div>

  </div>
</ng-container>
